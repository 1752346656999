@use './ng-select' as ng-select;

// Base input styles
.husky-input {
  @apply h-full w-full flex-1 border-0 bg-transparent px-3 leading-normal ring-0 focus-visible:outline-none;

  &.husky-input-prefix ~ & {
    @apply pl-2;
  }
}

// Form field container
.husky-form-field {
  @apply relative;

  // Label base styles
  .husky-label {
    @apply mb-1 inline-block select-none text-sm;
  }

  // Input/Textarea shared styles
  &--input,
  &--textarea {
    .husky-form-field__input-wrapper {
      @apply flex w-full items-center rounded-md border border-gray-300 bg-gray-50 text-base text-gray-900 transition duration-200 ease-out;
    }

    // States
    &.husky-form-field--focused .husky-form-field__input-wrapper {
      @apply border-indigo-500 ring-1 ring-primary-500 bg-white;
    }

    &.husky-form-field--invalid .husky-form-field__input-wrapper {
      @apply border-red-500;
    }

    &.husky-form-field--disabled .husky-form-field__input-wrapper {
      @apply border-gray-200 bg-gray-100 text-gray-500;
    }

    &:not(.husky-form-field--focused):not(.husky-form-field--disabled):not(.husky-form-field--invalid) {
      .husky-form-field__input-wrapper {
        @apply hover:border-gray-400;
      }
    }

    // Sizes
    &.husky-form-field--sm {
      .husky-label {
        @apply text-xs;
      }

      &.husky-form-field--floating .husky-label {
        @apply text-2xs;
      }

      .husky-form-field__input-wrapper {
        @apply min-h-9 text-sm;
      }
    }

    &.husky-form-field--md .husky-form-field__input-wrapper {
      @apply min-h-11;
    }

    &.husky-form-field--lg .husky-form-field__input-wrapper {
      @apply min-h-12;
    }

    &.husky-form-field--xl .husky-form-field__input-wrapper {
      @apply h-12 text-xl;
    }

    &.husky-form-field--2xl .husky-form-field__input-wrapper {
      @apply h-12 text-2xl;
    }
  }

  // Textarea specific
  &--textarea {
    .husky-form-field__input-wrapper {
      @apply h-auto p-2;
    }

    .husky-input {
      @apply h-auto leading-normal px-0;
    }
  }

  // Floating label styles
  &--floating {
    .husky-label {
      @apply mb-0;
    }

    .husky-form-field__label-container {
      @apply absolute left-0 top-0 w-full pointer-events-none;
      z-index: 1002; // ng-select is 1001 when focused

      .husky-label {
        @apply absolute transition-all duration-200;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;

        &::before {
          content: "";
          @apply absolute inset-0;
          top: 50%;
          transform: translateY(-50%);
          height: 2px;
          background-color: theme('colors.gray.50');
          margin: 0 -4px;
          z-index: -1;
        }
      }
    }

    // Floating states
    &.husky-form-field--filled,
    &.husky-form-field--focused {
      .husky-label {
        top: 0;
        transform: translateY(-50%);

        &::before {
          background-color: theme('colors.white');
        }
      }
    }

    &.husky-form-field--focused .husky-label {
      @apply text-indigo-500;
    }

    &.husky-form-field--invalid .husky-label {
      @apply text-red-500;
    }

    &.husky-form-field--disabled {
      .husky-form-field__label-container .husky-label {
        @apply text-gray-500;

        &::before {
          background-color: theme('colors.gray.100');
        }
      }

      &.husky-form-field--filled,
      &.husky-form-field--focused {
        .husky-form-field__label-container .husky-label::before {
          background-color: theme('colors.gray.100');
        }
      }
    }
  }
}

// Additional components
.husky-form-field-error {
  @apply mx-3 mt-1 block text-xs text-red-500;
}

:root {
  .husky-input-prefix--icon {
    @apply ml-2 flex h-full items-center;
  }
}

.husky-form-field--sm {
  @include ng-select.ng-select(text-sm, h-9, min-h-9);

  .husky-label {
    @apply text-xs;
  }

  &.husky-form-field--floating .husky-label {
    @apply text-2xs;
  }
}

.husky-input-suffix.husky-icon-button {
  @apply mr-1;
}
