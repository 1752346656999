.husky-dialog {
  display: block;
  animation: husky-dialog-scale-in 250ms cubic-bezier(0, 0, 0.2, 1);
}

@keyframes husky-dialog-scale-in {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes husky-dialog-scale-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}
