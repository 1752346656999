.ng-dropdown-panel {
  @apply rounded-b-md border border-indigo-500 bg-white text-base shadow-sm;
}

@mixin ng-select($fontSizeClass, $heightClass, $minHeightClass) {
  .ng-select {
    .ng-select-container {
      @apply #{$fontSizeClass} #{$minHeightClass} rounded-md border border-gray-300 bg-gray-50 text-gray-900 transition duration-200 ease-out hover:border-gray-400 focus:bg-white;

      .ng-value-container {
        .ng-placeholder {
          width: 50px;
        }
      }
    }

    &.ng-select-disabled {
      .ng-select-container {
        @apply border-gray-200 bg-gray-100 text-gray-500;
      }

      .ng-arrow-wrapper:hover .ng-arrow {
        /** Stay at default color */
        border-top-color: #999;
      }
    }

    &.ng-select-opened,
    &.ng-select-focused {
      .ng-select-container {
        @apply border-indigo-500;
      }
    }

    &.ng-select-single {
      .ng-select-container {
        @apply #{$heightClass};

        .ng-value-container {
          @apply h-full px-3;

          .ng-input {
            @apply top-0 h-full;

            input {
              @apply h-full;
            }
          }
        }
      }
    }

    &.ng-select-multiple {
      .ng-select-container {
        @apply #{$minHeightClass};

        .ng-value-container {
          @apply h-full px-3;

          .ng-value {
            @apply bg-transparent;

            .ng-value-label {
              @apply bg-primary-100 rounded-l;
            }

            .ng-value-icon {
              @apply bg-primary-100 rounded-r transition;

              &:hover {
                @apply bg-primary-200 rounded-r;
              }
            }
          }

          .ng-placeholder {
            top: 9px;
          }
        }
      }
    }

    &.ng-select-focused {
      &:not(.ng-select-opened) > .ng-select-container {
        @apply ring-0;
      }
    }

    .ng-arrow-wrapper {
      @apply transition duration-100;
    }
  }
}

@include ng-select(text-base, h-11, min-h-11);
