.husky-icon-button {
  @apply flex h-10 w-10 items-center justify-center rounded-full text-center transition duration-100 hover:bg-gray-800/10 active:bg-gray-800/20 disabled:bg-transparent disabled:text-gray-400 disabled:hover:bg-transparent;
}

.husky-icon-button--sm {
  @apply h-8 w-8;
}

.husky-icon-button--2xs {
  @apply h-6 w-6;

  .mat-icon {
    height: 20px;
    width: 20px;
    line-height: 20px;
    font-size: 20px;
  }
}

.husky-icon-button--xs {
  @apply h-7 w-7;
}

.husky-icon-button--lg {
  @apply h-12 w-12;
}

.husky-icon-button--xl {
  @apply h-14 w-14;
}

.husky-button {
  @apply inline-flex select-none items-center justify-center truncate whitespace-nowrap rounded-lg px-4 py-2 text-base font-medium shadow-sm transition duration-200 ease-out;
}

.husky-button--lg {
  @apply px-6 py-2.5 text-base;

  .husky-button__icon {
    height: 24px;
    width: 24px;
    line-height: 24px;
    font-size: 24px;

    /** More specificity needed for mat-icon */
    &.mat-icon {
      height: 24px;
      width: 24px;
    }
  }
}

.husky-button--sm {
  @apply px-3 py-1.5 text-xs;

  .husky-button__icon {
    height: 16px;
    width: 16px;
    line-height: 16px;
    font-size: 16px;

    /** More specificity needed for mat-icon */
    &.mat-icon {
      height: 16px;
      width: 16px;
    }
  }
}

.husky-button--primary {
  @apply bg-primary-600 text-on-primary-600 hover:bg-primary-700 active:bg-primary-800;
}

.husky-button--primary-light {
  @apply bg-primary-50 text-primary-700 hover:bg-primary-100 active:bg-primary-200 border-primary-700 border;
}

.husky-button--outlined {
  @apply border border-gray-300 bg-white text-gray-700 shadow-sm hover:border-gray-400 hover:bg-gray-50 active:bg-gray-100;
}

.husky-button--danger {
  @apply bg-red-600 text-white hover:bg-red-700 active:bg-red-800;
}

.husky-button--disabled {
  @apply cursor-not-allowed bg-gray-200 text-gray-400 border-gray-200 shadow-none hover:bg-gray-200;
}

.husky-button--text {
  @apply bg-transparent text-gray-700 shadow-none px-2 py-1.5 text-sm
  hover:bg-gray-100 active:bg-gray-200
  disabled:bg-transparent disabled:text-gray-400 disabled:hover:bg-transparent;
}

// Adjust icon sizes for text variant
.husky-button--text {
  .husky-button__icon {
    height: 18px;
    width: 18px;
    line-height: 18px;
    font-size: 18px;

    /** More specificity needed for mat-icon */
    &.mat-icon {
      height: 18px;
      width: 18px;
    }
  }
}

.husky-button__icon {
  @apply flex-none;
  height: 20px;
  width: 20px;
  line-height: 20px;
  font-size: 20px;

  /** More specificity needed for mat-icon */
  &.mat-icon {
    height: 20px;
    width: 20px;
  }
}

.husky-button__icon--start {
  @apply -ml-1 mr-2;
}

.husky-button__icon--end {
  @apply -mr-1 ml-2;
}
