.husky-line-clamp {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.husky-line-clamp__more-button,
.husky-line-clamp__less-button {
  @apply mt-2 flex w-full items-center justify-center gap-2 rounded-full border p-1.5 text-center transition hover:bg-slate-100;

  svg {
    @apply h-[18px] w-[18px];
  }
}
