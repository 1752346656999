.husky-image-overlay {
  @apply bg-white/90 flex items-center justify-center opacity-0 transition-opacity duration-200 pointer-events-none;
}

.husky-image-spinner {
  @apply border-4 border-gray-200 rounded-full;
  border-top-color: theme('colors.primary.600');
  animation: husky-spin 1s linear infinite;
  width: min(20%, 80px);
  aspect-ratio: 1;
}

.husky-image-error {
  @apply opacity-30 grayscale;
}

@keyframes husky-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
