@tailwind base;

@import './styles/misc';

@layer components {
  @import './styles/button';
  @import './styles/dialog';
  @import './styles/input';
  @import './styles/layout';
  @import 'styles/line';
}

@import './styles/fonts';
@import "material-symbols/rounded";
@import './styles/drag-drop';
@import './styles/angular-material';
@import './styles/angular-material-overwrites';
@import './styles/ag-grid';
@import './styles/media';
@import '../../../libs/app/shared/ui-clamp/src/lib/clamp/clamp-styles';

@import '@ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
@import '@ag-grid-community/styles/ag-theme-quartz.css'; // Optional theme CSS

@import '@ng-select/ng-select/themes/default.theme.css';

@import '@angular/cdk/overlay-prebuilt.css';

@import "@fancyapps/ui/dist/fancybox/fancybox.css";
@import "@fancyapps/ui/dist/carousel/carousel.css";

@import './styles/ck-editor';
@import './styles/hot-toast';
@import './styles/ng-select';

@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv11', 'salt', 'calt', 'case';
  @apply font-sans antialiased;
}

html, body, .overflow-auto, .overflow-scroll, .overflow-y-auto, .overflow-y-scroll {
  @apply overscroll-y-contain;
}

:root {
  --padding-gutter: 1rem;
}

@screen lg {
  :root {
    --padding-gutter: 1.5rem;
  }
}

.husky-media-grid {
  @apply grid w-full;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: 150px;
  grid-gap: 0.5rem;

  @screen lg {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
