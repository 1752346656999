.ag-root-wrapper {
  border: none;
}

.ag-theme-quartz {
  --ag-font-family: theme('fontFamily.sans');
  --ag-active-color: theme('colors.primary.500');
  --ag-row-hover-color: theme('colors.slate.800 / 5%');
  --ag-row-border-color: theme('colors.slate.300');
  --ag-foreground-color: theme('colors.slate.900');
  --ag-header-background-color: theme('colors.slate.50');
}
