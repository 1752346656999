/** Hides the invoice form inputs when not hovering */
//.invoice-line {
//  &:not(:hover):not(:focus-within) {
//    .husky-form-field__input-wrapper,
//    .ng-select .ng-select-container {
//      background: transparent;
//      border-color: transparent;
//    }
//
//    .ng-select .ng-arrow-wrapper {
//      opacity: 0;
//    }
//  }
//}

.sales-document-line-grid {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 320px 70px 90px 140px 60px 100px 85px 110px 110px;
  gap: theme('spacing.2');
  @apply px-3 pb-2;
}

.sales-document-line-grid--text {
  grid-template-columns: 350px;
}

.sales-document-line-grid-row-2 {
  @apply py-2 pl-3;
  display: grid;
  gap: theme('spacing.2');
  grid-template-columns: 350px;
}

.purchase-order-line {
  @apply grid items-center gap-2;
  grid-template-columns: minmax(240px, 1fr) 210px 80px 90px 110px 80px 110px 110px 32px;
}

.purchase-order-line {
  &:not(:hover):not(:focus-within) {
    .husky-form-field__input-wrapper,
    .ng-select .ng-select-container {
      background: transparent;
      border-color: transparent;
    }

    .ng-select .ng-arrow-wrapper {
      opacity: 0;
    }
  }
}

.purchase-invoice-line {
  @apply grid items-center gap-2;
  grid-template-columns: minmax(240px, 1fr) minmax(210px, 1fr);
}

.purchase-invoice-line-2 {
  @apply grid items-center gap-2;
  /** Make columns grow proportionally */
  grid-template-columns: minmax(80px, 80 * 1fr) minmax(90px, 90 * 1fr) minmax(
      120px,
      120 * 1fr
    ) minmax(80px, 80 * 1fr) minmax(110px, 110 * 1fr) minmax(110px, 110 * 1fr);
}

.warehouse-receipt-line {
  @apply grid items-center gap-2;
  grid-template-columns: 1fr 80px 90px;
}
