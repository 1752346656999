.mat-mdc-form-field-error {
  @apply text-xs;
}

:root .mdc-tab {
  @apply px-3 text-sm min-w-[60px];

  &.mdc-tab--active {
    @apply bg-primary-50 font-medium;
  }
}

.mat-mdc-tooltip {
  > * {
    font-size: 0.75rem !important;
    text-align: center !important;
  }
}

:root .mat-mdc-dialog-surface {
  @apply rounded-xl;
}
