/** Hide native up/down buttons for input[type="number"] */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield !important;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

/** Fixed cdkTextareaAutosize measuring when there is a padding on the input */
.cdk-textarea-autosize-measuring-firefox {
  padding: 0 !important; // Turn around for https://github.com/angular/components/issues/26997
}
