.cdk-drag-preview {
  @apply rounded-lg border bg-white shadow;
}

.cdk-drag-placeholder {
  opacity: 0.2;
}

.cdk-drop-list-dragging .cdk-drag-placeholder,
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1) !important;
}
